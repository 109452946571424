import { QrScanner } from "@yudiel/react-qr-scanner";
import { IScanProps } from "../Interface/interface";

import css from '../css/ScanBarCode.module.css'

const ScanBarCode: React.FC<IScanProps> = ({ setBarCodeValue, setShowBarCodeReader }) => {
    const content = (
        <>
            <section className={css.MainSection}>   
                    <div className={css.ControlDiv}>
                        <i className="fi fi-bs-x" onClick={() => setShowBarCodeReader(false)} />
                    </div>
                    <div className={css.ScanBarCodeDiv}>
                        <QrScanner
                            onDecode={(value) => setBarCodeValue(value)}
                            onError={(error) => console.error(error.message)}
                        />
                    </div>
            </section>
        </>
    )

    return content
}

export default ScanBarCode;