
const ReportStatusPage = () => {
    const content = (
        <>
        </>
    );

    return content;
}

export default ReportStatusPage;
