import { Link } from "react-router-dom"

export const C0211MenuItem = [
    {
        typeid: 1,
        name: "主頁",
        icon: "fi fi-rr-home",
        isShow: true,
        Link: "/C0211",
    },
    {
        typeid: 2901,
        name: "HR 管理",
        icon: "fi fi-rs-function-process",
        isShow: true,
        Link: "",
    },
    {
        typeid: 2902,
        name: "倉存管理",
        icon: "fi fi-rr-garage",
        isShow: true,
        Link: "",
    },
    {
        typeid: 2903,
        name: "銷售管理",
        icon: "fi fi-rs-benefit-porcent",
        isShow: false,
        Link: "",
    },
    {
        typeid: 2999,
        name: "系統管理員",
        icon: "fi fi-bs-admin-alt",
        isShow: false,
        Link: "",
    }

]

export const C0211SubFunctionList = [
    {
        function_typeID: 2901,
        function_id: 2001,
        function_name: "員工列表",
        function_icon: "fi fi-rs-member-list",
        function_icon_BGcolor: "#7a5298",
        desc: "可以查看/編輯所有用户的列表",
        permission_id: 2001,
        function_path: "/C0211/UserList",
        function_status: "開發中",
        isShow: true,
    },
    {
        function_typeID: 2902,
        function_id: 2002,
        function_name: "產品列表",
        function_icon: "fi fi-rr-boxes",
        function_icon_BGcolor: "#168369",
        desc: "可以查看/編輯所有產品的資訊包括: (產品類別, 產品SKU)",
        permission_id: 2002,
        function_path: "/C0211/Product",
        function_status: "開發中",
        isShow: true,
    },
    {
        function_typeID: 2902,
        function_id: 2003,
        function_name: "倉存管理",
        function_icon: "fi fi-br-box",
        function_icon_BGcolor: "#168369",
        desc: "可以查看/編輯倉存數量",
        permission_id: 2002,
        function_path: "/C0211/Storage",
        function_status: "開發中",
        isShow: true,
    },
]

