import css from '../../../css/C0211/StockRecord.module.css'
import { IC0211StockListPagePronps } from '../../../Interface/interface'

const StockRecordStatus: React.FC <IC0211StockListPagePronps> = ({ isdata, handleFunctionRouter }) => {
    const content = (
        <>
            <div className={css.ProductTableDiv}>
                <table id="ProductListTable" style={{width: '100%'}}>
                    <thead style={{backgroundColor: '#d298ff'}}>
                        <tr>
                            <th style={{width: '12%', borderRight: '1px rgb(26, 26, 26) solid'}}>訂單編號</th>
                            <th style={{width: '8%', borderRight: '1px rgb(26, 26, 26) solid'}}>出發地</th>
                            <th style={{width: '8%', borderRight: '1px rgb(26, 26, 26) solid'}}>目的地</th>
                            <th style={{width: '8%', borderRight: '1px rgb(26, 26, 26) solid'}}>貨品項目數量</th>
                            <th style={{width: '10%', borderRight: '1px rgb(26, 26, 26) solid'}}>訂單狀態</th>
                            <th style={{width: '10%', borderRight: '1px rgb(26, 26, 26) solid'}}>更新日期</th>
                            <th style={{width: '10%'}}>指令</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {
                            isData.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{data.id}</td>
                                        <td>{data.loginID}</td>
                                        <td>{data.email}</td>
                                        <td>{data.role}</td>
                                        <td>
                                            <button className={css.edit}>編輯</button>
                                        </td>
                                    </tr>
                                )
                            })
                        } */}
                    </tbody>
                </table>
            </div>
        </>
    )

    return content
}

export default StockRecordStatus