import axios from 'axios'
import { Cookies } from 'react-cookie';
import { SystemContextType } from '../Class/SystemPara';
import { PreLoadingContextType } from '../Class/C0211PreLoadingData';
import { UserContextType } from '../Class/UserPara';

const cookies = new Cookies();

export const AzureStorgeLink = "https://wisetechstorage.blob.core.windows.net"
export const sasToken = "?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacuptfx&se=2031-06-04T10:48:46Z&st=2021-06-04T02:48:46Z&spr=https&sig=%2BkOldhVIFZRvDjh9iNADaik4s7SJwrw0k31iYg51d7Q%3D";

//登出帳號
export const LogoutFunction = (systemContext: SystemContextType) => {
    systemContext.setLoginStatus(false)
    cookies.remove('ACID')
    cookies.remove('token')
    window.location.href = "/"
}

//取得使用者資料
export const FetchSystemData = async (systemContext: SystemContextType) => {
    let ACID = cookies.get('ACID')
    let token = cookies.get('token')

    if (ACID === "c0211") {
        try {
            // const res = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
            //     headers: { Authorization: `Bearer ${token}` }
            // });

            const resPermission = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserPermission`, {
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (resPermission.status === 200) {
                systemContext.setLoginStatus(true)

            }
            
        } catch (error) {
            console.log(error)

        }
    } else if (ACID === "c0000") {
        try {

            console.log("Running FetchSystemData")

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/menu/GetFunction`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (res.status === 200) {
                systemContext.setFunctionArr(res.data.permissions)
                systemContext.setLoginStatus(true)
            }

        } catch (error) {
            console.log(error)
        }
    } else if (ACID === "c0217") {
        try {

            const loginres = await axios.post(`${process.env.REACT_APP_API_URL}/c0217_vba/user/GetUserDataByToken`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (loginres.status === 200) {
                systemContext.setLoginStatus(true)
            }

        } catch (error) {
            console.log(error)
        } finally {
        }
    }
}

//取得系統資料
export const C0211PreLoadingData = async (userContext: UserContextType, preLoadingContext: PreLoadingContextType, Range: string = "ALL") => {
    let token = cookies.get('token')

    const checkPermission = (functionCode: number) =>
        userContext.FuncPermission.some(item => item.function === functionCode);

    switch (Range) {
        case "ALL":
            if (checkPermission(2001)) {
                await LoadData2001(preLoadingContext, token);
            }
            if (checkPermission(2002)) {
                await LoadData2002(preLoadingContext, token);
            }
            break;
        case "2001":
            if (checkPermission(2001)) {
                await LoadData2001(preLoadingContext, token);
            }
            break;
    }

}

//獲取用戶列表資料
const LoadData2001 = async (preLoadingContext: PreLoadingContextType, token: string) => {
    try {
        const resUserList = await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserList`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (resUserList.status === 200) {
            preLoadingContext.setLoadUserData(resUserList.data.User);
        }
    } catch (error) {
        console.log(error);
    }
};

//獲取產品列表資料
const LoadData2002 = async (preLoadingContext: PreLoadingContextType, token: string) => {
    try {
        const resProductType= await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/InsertProductType`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        const resProduct= await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductList`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        const resProductSKU= await axios.get(`${process.env.REACT_APP_API_URL}/c0502_vba/product/goUpdateProductList`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        const resProductSKURef= await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/product/GetProductSKURef`, {},{
            headers: { Authorization: `Bearer ${token}` }
        });

        if (resProductType.status === 200) {
            preLoadingContext.setLoadProductTypeList(resProductType.data['Product Type']);
        }

        if (resProduct.status === 200) {
            preLoadingContext.setLoadProductList(resProduct.data.Product);
        }

        if (resProductSKU.status === 200) {
            preLoadingContext.setLoadProductSKUList(resProductSKU.data.Product);
        }

        if (resProductSKURef.status === 200) {
            preLoadingContext.setLoadProductSKURefList(resProductSKURef.data.ProductSKURef);
        }

    } catch (error) {
        console.log(error);
    }
};


