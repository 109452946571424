import React, { useEffect } from 'react'
import css from '../../../css/C0211/StockProductDetail.module.css'
import { IC0211StockProductDetail, IC0211StockProductDetailProps, IC0211StockProductInOutRecord } from '../../../Interface/interface'
import { Cookies } from 'react-cookie'
import axios from 'axios'
const cookies = new Cookies()

const StockProductDetail: React.FC<IC0211StockProductDetailProps> = ({ ProductSKUID, setProductDetailShow, setLoading }) => {

    const [isDisplayMode, setDisplayMode] = React.useState<number>(1)

    const [isProductDetail, setProductDetail] = React.useState<IC0211StockProductDetail>()

    useEffect(() => {
        if (ProductSKUID !== -1) {
            setLoading(true)
            getStockInOutRecord()
        }
    }, [])

    const getStockInOutRecord = async () => {
        const token = cookies.get('token')
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/GetProductWarehouseList`, {
                Product_Sku_ID: ProductSKUID,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status === 200) {
                setProductDetail(res.data)
                console.log(res.data)
            }

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.BackgroundDiv}>

                    <div className={css.HeaderDiv}>
                        <p className={css.Pagetitle}>查看貨品資料</p>

                        <div className={css.ExitPageDiv}>
                            <i className="fi fi-br-times-hexagon" onClick={() => setProductDetailShow(false)} />
                        </div>
                    </div>

                    <div className={css.ProductDisplayFieldDiv}>
                        <div className={css.ProductDetailDiv}>

                            <div className={css.EachGroupInputDiv}>
                                <div className={css.EachProductDetailDiv} >
                                    <label>貨品編號</label>
                                    <input
                                        type="text"
                                        value={isProductDetail?.ProductSkuList.Product_Code ?? ''}
                                    />
                                </div>

                                <div className={css.EachProductDetailDiv}>
                                    <label>貨品名稱</label>
                                    <input
                                        type="text"
                                        value={isProductDetail?.ProductSkuList.Product_Name ?? ''}
                                    />
                                </div>
                            </div>

                            <div className={css.EachGroupInputDiv}>
                                <div className={css.EachProductDetailDiv}>
                                    <label>描述</label>
                                    <input
                                        type="text"
                                        value={isProductDetail?.ProductSkuList.Product_Desc ?? ''}
                                    />
                                </div>
                            </div>





                            <div className={css.EachProductDetailDiv}>
                                <label>倉存量</label>
                                <input
                                    type="text"
                                    value={isProductDetail?.ProductSkuList.Product_Total_Stock ?? ''}
                                />
                            </div>

                            <div className={css.EachProductDetailDiv}>
                                <label>製作時間</label>
                                <input
                                    type="text"
                                    value={isProductDetail?.ProductSkuList.Product_Arrival_Day ?? ''}
                                />
                            </div>

                            <div className={css.EachProductDetailDiv}>
                                <label>狀態</label>
                                <input
                                    type="text"
                                    value={isProductDetail?.ProductSkuList.Product_Sku_Status ?? ''}
                                />
                            </div>
                        </div>

                        <div className={css.ProductDetailDiv}>
                            <div className={css.EachProductDetailIDiv}>
                                <label>最小安全量</label>
                                <input
                                    type="text"
                                    value={isProductDetail?.ProductSkuList.Product_Min_Safe_Stock ?? ''}
                                />
                                <i className="fi fi-br-pencil" onClick={() => setProductDetailShow(false)} />
                            </div>

                            <div className={css.EachProductDetailIDiv}>
                                <label>最大安全量</label>
                                <input
                                    type="text"
                                    value={isProductDetail?.ProductSkuList.Product_Max_Safe_Stock ?? ''}
                                />
                                <i className="fi fi-br-pencil" onClick={() => setProductDetailShow(false)} />
                            </div>
                        </div>

                        <div className={css.ProductDetailDiv}>
                            <div className={css.EachProductDetailDiv}>
                                <label>條碼</label>
                                <input
                                    type="text"
                                    value={isProductDetail?.ProductSkuList.Product_Barcode ?? ''}
                                />
                            </div>
                        </div>

                    </div>

                    <div className={css.ProductStockWarehouseDiv}>
                        <table id="ProductListTable" style={{ width: '98%', margin: 'auto' }}>
                            <thead style={{ backgroundColor: '#FFE8B1' }}>
                                <tr>
                                    <th style={{ width: '5%', borderRight: '1px rgb(26, 26, 26) solid' }}>倉庫</th>
                                    <th style={{ width: '6%', borderRight: '1px rgb(26, 26, 26) solid' }}>數量</th>
                                    <th style={{ width: '9%', borderRight: '1px rgb(26, 26, 26) solid' }}>備註</th>
                                    <th style={{ width: '10%'}}>最後更新日期</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isProductDetail?.ProductWarehouseList.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data.Product_Warehouse_Location}</td>
                                                <td>{data.Warehouse_Stock}</td>
                                                <td className={css.EditInputTd}>
                                                    <input
                                                        type="text"
                                                        value={data.Warehouse_Remarks}
                                                    />
                                                    <i className="fi fi-br-pencil" onClick={() => setProductDetailShow(false)} />
                                                </td>
                                                <td>{data.Warehouse_Update_On}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>



                </div>
            </section>
        </>
    )

    return content
}

export default StockProductDetail