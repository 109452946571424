import React, { useState } from 'react'
import css from '../../../css/C0211/StockCreateTicket.module.css'
import { IC0211StockCreateTicketProps } from '../../../Interface/interface'

const StockCreateTicket: React.FC<IC0211StockCreateTicketProps> = ({ setStockCreateTicketShow, setShowAlert, setAlertType, setAlertMessage, setLoading }) => {

    const [requireData, setRequireData] = useState({
        OrderTicketID: '',
        POOrderID: '',
        Date: '',
        ContactName: '',
        ContactNumber: '',
        Supplier: '',
        Address: ''
    })


    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.BackgroundDiv}>
                    <div className={css.HeaderDiv}>
                        <p>查看貨品資料</p>
                        <i className="fi fi-br-times-hexagon" onClick={() => setStockCreateTicketShow(false)} />
                    </div>

                    <div className={css.MainUi}>
                        <div className={css.BasicInputDiv}>
                            <div className={css.EachInputDiv}>
                                <label>訂單編號</label>
                                <input type="text" value={requireData.OrderTicketID} onChange={(e) => setRequireData({ ...requireData, OrderTicketID: e.target.value })} />
                            </div>
                            <div className={css.EachInputDiv}>
                                <label>PO訂單編號</label>
                                <input type="text" value={requireData.POOrderID} onChange={(e) => setRequireData({ ...requireData, POOrderID: e.target.value })} />
                            </div>

                            <div className={css.EachInputDiv}>
                                <label>日期</label>
                                <input type="text" value={requireData.Date} onChange={(e) => setRequireData({ ...requireData, Date: e.target.value })} />
                            </div>

                            <div className={css.EachInputDiv}>
                                <label>聯絡人</label>
                                <input type="text" value={requireData.ContactName} onChange={(e) => setRequireData({ ...requireData, ContactName: e.target.value })} />
                            </div>

                            <div className={css.EachInputDiv}>
                                <label>聯絡電話</label>
                                <input type="text" value={requireData.ContactNumber} onChange={(e) => setRequireData({ ...requireData, ContactNumber: e.target.value })} />
                            </div>

                            <div className={css.EachInputDiv}>
                                <label>供應商</label>
                                <input type="text" value={requireData.Supplier} onChange={(e) => setRequireData({ ...requireData, Supplier: e.target.value })} />
                            </div>

                            <div className={css.EachInputDiv}>
                                <label>地址</label>
                                <input type="text" value={requireData.Address} onChange={(e) => setRequireData({ ...requireData, Address: e.target.value })} />
                            </div>
                        </div>

                        <div className={css.ProductTableDiv}>

                            <table id="ProductListTable" style={{ width: '100%' }}>
                                <thead style={{ backgroundColor: '#FFB300' }}>
                                    <tr>
                                        <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>貨品編號</th>
                                        <th style={{ width: '12%', borderRight: '1px rgb(26, 26, 26) solid' }}>貨品名稱</th>
                                        <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>數量</th>
                                        <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>狀態</th>
                                        <th style={{ width: '10%' }}>指令</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                            isData.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{data.id}</td>
                                        <td>{data.loginID}</td>
                                        <td>{data.email}</td>
                                        <td>{data.role}</td>
                                        <td>
                                            <button className={css.edit}>編輯</button>
                                        </td>
                                    </tr>
                                )
                            })
                        } */}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </ section>
        </>
    )

    return content
}

export default StockCreateTicket