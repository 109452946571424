import { Link } from 'react-router-dom'
import css from '../../../css/C0211/NavBarV2.module.css'
import { Cookies } from "react-cookie";
import { useContext, useEffect, useState } from 'react';
import { SystemContext } from '../../../Class/SystemPara';
import { IC0211NavBarProps } from '../../../Interface/interface';
import { C0211MenuItem, C0211SubFunctionList } from '../../../Class/FunctionList';
import ACLogo from '../../../global/C0211/SHYLogo.png'
import { UserContext } from '../../../Class/UserPara';
const cookies = new Cookies();

const C0211Navbar: React.FC<IC0211NavBarProps> = ({ isShowMenu, handleMenu }) => {

    const userContext = useContext(UserContext);
    const [activeMenu, setActiveMenu] = useState<number | null>(null);

    useEffect(() => {
        if (isShowMenu === false) {
            setActiveMenu(null);
        }
    }, [isShowMenu]);

    const handleShowSubMenu = (typeid: number) => {
        handleMenu(true)
        setActiveMenu(activeMenu === typeid ? null : typeid);
    };

    // const { systemData, setFunctionArr, setLoginStatus} = useContext(SystemContext);

    // const FncLogin = () => {
    //     cookies.remove('token');
    //     setLoginStatus(false)
    // }

    const content = (
        <>
            <div className={css.NavBar}>

                {
                    isShowMenu && (
                        <div className={css.ACLogoDiv} onClick={() => handleMenu(false)}>
                            <Link to='/C0211'>
                                <img src={ACLogo} alt='Company Logo' />
                            </Link>
                        </div>
                    )
                }

                {C0211MenuItem.filter(item => item.isShow && userContext.FuncPermission.some(permission => permission.function === item.typeid)).map((item, index) => (
                    <div key={index}>
                        {
                            item.Link.length === 0 ? (
                                <div
                                    className={isShowMenu ? css.EachItem : css.EachItemHide}
                                    onClick={() => handleShowSubMenu(item.typeid)}
                                >
                                    <i className={item.icon} />
                                    <p>{item.name}</p>
                                </div>
                            ) : (
                                <Link to={item.Link} className={css.ItemLink} key={index}>
                                    <div
                                        className={isShowMenu ? css.EachItem : css.EachItemHide}
                                        onClick={() => handleShowSubMenu(item.typeid)}
                                    >
                                        <i className={item.icon} />
                                        <p>{item.name}</p>
                                    </div>
                                </Link>
                            )
                        }

                        {/* SubMenu Display Based on Active Menu */}
                        {activeMenu === item.typeid && (
                            <div className={css.SubMenu}>
                                {C0211SubFunctionList.filter(subitem => subitem.isShow && subitem.function_typeID === item.typeid)
                                    .map((subitem, subindex) => (
                                        <Link to={subitem.function_path} className={css.SubItemLink} key={subindex}>
                                            <div className={css.SubItem} key={subindex}>
                                                <i className={subitem.function_icon} />
                                                <p>{subitem.function_name}</p>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    )

    return content
}

export default C0211Navbar