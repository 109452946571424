import css from '../../../css/C0211/StockRecord.module.css'
import { IC0211StockListPagePronps } from '../../../Interface/interface'

const StockEnoughStatus: React.FC<IC0211StockListPagePronps> = ({ isdata, handleFunctionRouter }) => {
    const content = (
        <>
            <div className={css.ProductTableDiv}>
                <table id="StockTable" style={{ width: '100%' }}>
                    <thead style={{ backgroundColor: '#6cf8ab' }}>
                        <tr>
                            <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>貨品編號</th>
                            <th style={{ width: '25%', borderRight: '1px rgb(26, 26, 26) solid' }}>貨品名稱</th>
                            <th style={{ width: '10%', borderRight: '1px rgb(26, 26, 26) solid' }}>倉庫</th>
                            <th style={{ width: '10%', borderRight: '1px rgb(26, 26, 26) solid' }}>倉存量</th>
                            <th style={{ width: '40%' }}>指令</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isdata.filter((product) => product.Product_Total_Stock > product.Product_Min_Safe_Stock).map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{data.Product_Code}</td>
                                        <td>{data.Product_Name}</td>
                                        <td>{data.Product_Warehouse_Location}</td>
                                        <td>{data.Product_Total_Stock}</td>
                                        <td>
                                            <div className={css.CommandBlockDiv}>
                                                <button className={css.CommandBlock} onClick={() => handleFunctionRouter('detail', data.Product_Sku_ID)}><i className="fi fi-br-square-info" />貨品資料</button>
                                                <button className={css.CommandBlock} onClick={() => handleFunctionRouter('record', data.Product_Sku_ID)}><i className="fi fi-br-square-info" />出入倉記錄</button>
                                                <button className={css.CommandBlock} onClick={() => handleFunctionRouter('edit', data.Product_Sku_ID)}><i className="fi fi-br-square-info" />其他</button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )

    return content
}

export default StockEnoughStatus