import React, { useEffect } from 'react'
import css from '../../../css/C0211/StockProductInOutRecord.module.css'
import { IC0211StockListInOutRecordProps, IC0211StockProductInOutRecord } from '../../../Interface/interface'
import { Cookies } from 'react-cookie'
import axios from 'axios'
const cookies = new Cookies()

const StockProductInOutRecord: React.FC<IC0211StockListInOutRecordProps> = ({ ProductSKUID, setStockInOutRecordShow, setLoading }) => {

    const [isDisplayMode, setDisplayMode] = React.useState<number>(1)

    const [isProductInOutRecord, setProductInOutRecord] = React.useState<IC0211StockProductInOutRecord>()

    useEffect(() => {
        if (ProductSKUID !== -1) {
            setLoading(true)
            getStockInOutRecord()
        }
    }, [])

    const getStockInOutRecord = async () => {
        const token = cookies.get('token')
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0502/product/GetOrderProductLog`, {
                Product_Sku_ID: ProductSKUID,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            })

            if (res.status === 200) {
                setProductInOutRecord(res.data)
                console.log(res.data)
            }

        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const displayTableType = () => {
        switch (isDisplayMode) {
            case 1:
                return isProductInOutRecord?.OrderProductDetailList.map((data, index) => {
                        return (
                            <tr key={index}>
                                <td>{data.Order_Detail_Date}</td>
                                <td>{data.Order_Detail_Type}</td>
                                <td>{data.Order_Detail_Amount}</td>
                                <td>{data.Order_Details_Status}</td>
                                <td>{data.Order_Detail_User}</td>
                                <td>{data.Order_Detail_Remark}</td>
                            </tr>
                        )
                    })
            case 2:
                return isProductInOutRecord?.OrderProductDetailList.filter(data => data.Order_Detail_Type === 'stock in').map((data, index) => {
                    return (
                        <tr key={index}>
                            <td>{data.Order_Detail_Date}</td>
                            <td>{data.Order_Detail_Type}</td>
                            <td>{data.Order_Detail_Amount}</td>
                            <td>{data.Order_Details_Status}</td>
                            <td>{data.Order_Detail_User}</td>
                            <td>{data.Order_Detail_Remark}</td>
                        </tr>
                    )
                })
            case 3:
                return isProductInOutRecord?.OrderProductDetailList.filter(data => data.Order_Detail_Type === 'stock out').map((data, index) => {
                    return (
                        <tr key={index}>
                            <td>{data.Order_Detail_Date}</td>
                            <td>{data.Order_Detail_Type}</td>
                            <td>{data.Order_Detail_Amount}</td>
                            <td>{data.Order_Details_Status}</td>
                            <td>{data.Order_Detail_User}</td>
                            <td>{data.Order_Detail_Remark}</td>
                        </tr>
                    )
                })
            default:
                return isProductInOutRecord?.OrderProductDetailList.map((data, index) => {
                    return (
                        <tr key={index}>
                            <td>{data.Order_Detail_Date}</td>
                            <td>{data.Order_Detail_Type}</td>
                            <td>{data.Order_Detail_Amount}</td>
                            <td>{data.Order_Details_Status}</td>
                            <td>{data.Order_Detail_User}</td>
                            <td>{data.Order_Detail_Remark}</td>
                        </tr>
                    )
                })
        }
    }

    const content = (
        <>
            <section className={css.MainSection}>
                <div className={css.BackgroundDiv}>
                    <div className={css.StockRecordHeaderDiv}>
                        <div className={css.StockTitleOption}>
                            <p className={css.PageTitle}>最近100次的記錄</p>
                            <div className={css.StockEachShowOption} style={{ backgroundColor: isDisplayMode === 1 ? '#FF4D00' : '#612208' }} onClick={() => setDisplayMode(1)}>
                                <p>全部</p>
                            </div>
                            <div className={css.StockEachShowOption} style={{ backgroundColor: isDisplayMode === 2 ? '#FF4D00' : '#612208' }} onClick={() => setDisplayMode(2)}>
                                <p>入倉</p>
                            </div>
                            <div className={css.StockEachShowOption} style={{ backgroundColor: isDisplayMode === 3 ? '#FF4D00' : '#612208' }} onClick={() => setDisplayMode(3)}>
                                <p>出倉</p>
                            </div>
                        </div>

                        <div className={css.ExitPageDiv}>
                            <i className="fi fi-br-times-hexagon" onClick={() => setStockInOutRecordShow(false)} />
                        </div>
                    </div>
                    <div className={css.DisplayProductName}>
                        <p>{isProductInOutRecord?.ProductSkuList.Product_Code} - {isProductInOutRecord?.ProductSkuList.Product_Name}</p>
                        <p className={css.ProductDescription}>{isProductInOutRecord?.ProductSkuList.Product_Weight}</p>
                    </div>

                    <div className={css.StockRecordTableDiv}>
                        <table id="ProductListTable" style={{ width: '98%' ,margin: 'auto' }}>
                            <thead style={{ backgroundColor: '#FFE8B1' }}>
                                <tr>
                                    <th style={{ width: '15%', borderRight: '1px rgb(26, 26, 26) solid' }}>日期</th>
                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>類別</th>
                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>數量</th>
                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>狀態</th>
                                    <th style={{ width: '8%', borderRight: '1px rgb(26, 26, 26) solid' }}>負責人</th>
                                    <th style={{ width: '20%', borderRight: '1px rgb(26, 26, 26) solid' }}>備註</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    displayTableType()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )

    return content
}

export default StockProductInOutRecord