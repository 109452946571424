import { Helmet, HelmetProvider } from "react-helmet-async"
import { Outlet } from "react-router-dom"
import axios from "axios"

import css from '../../css/C0211/DashboardV2.module.css'
import NavBar from '../../Components/C0211/Main/Navbar'
import { useContext, useEffect, useState } from "react"
import { Cookies } from "react-cookie";
import { UserContext } from "../../Class/UserPara";
import { PreLoadingContext } from "../../Class/C0211PreLoadingData"
import { C0211PreLoadingData } from "../../Interface/Const"
import DeafultLoadingBar from "../../Components/LoadingBar/DefaultLoadingBar"

const cookies = new Cookies();

const Dashboard = () => {

    const userContext = useContext(UserContext);
    const preLoadDataContext = useContext(PreLoadingContext);

    const [userToken] = useState(cookies.get('token'));
    const [isloading, setLoading] = useState<boolean>(false);

    const [isShowMenu, setShowMenu] = useState(false)

    useEffect(() => {
        const loadSystemData = async () => {
            try {
                await LoadUserPermission();
            } catch (error) {
                console.error("Error during loading user permissions:", error);
            }
        };

        if (userToken !== null) {
            loadSystemData();
        }
    }, [userToken]);

    useEffect(() => {
        if (userContext.FuncPermission?.length > 0) {
            const preloadData = async () => {
                try {
                    await C0211PreLoadingData(userContext, preLoadDataContext);
                } catch (error) {
                    console.error("Error during preloading data:", error);
                } finally {
                    setLoading(false);
                }
            };
            setLoading(true);
            preloadData();
        }
    }, [userContext?.FuncPermission]);

    const handleMenu = (isMenuVisible: boolean) => {
        setShowMenu(isMenuVisible)
    }

    const LoadUserPermission = async () => {
        let token = cookies.get('token');
        try {
            const resPermission = await axios.post(`${process.env.REACT_APP_API_URL}/c0502_vba/user/GetUserPermission`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (resPermission.status === 200) {
                userContext.setFunctionPermission(resPermission.data.UserPermission);
            }

        } catch (error) {
            console.log(error);
            window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/Login/`);
        }
    };

    const content = (
        <>
            <body>
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>紹香園 - 控制台</title>
                    </Helmet>
                    {isloading && <DeafultLoadingBar />}
                    <div className={isShowMenu ? css.Layout : css.HideLayout}>
                        <div className={css.NavBar} onClick={() => handleMenu(true)}>
                            <NavBar isShowMenu={isShowMenu} handleMenu={handleMenu} />
                        </div>
                        <div className={css.Outlet} onClick={() => setShowMenu(false)}>
                            <Outlet />
                        </div>
                    </div>
                </HelmetProvider>
            </body>
        </>
    )

    return content
}

export default Dashboard